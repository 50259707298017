import React from 'react';
import { Alert, Button, FormControl, Modal } from 'react-bootstrap';
import { FormikProvider, Form, useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { objectToFormData } from 'utils/formData';
import { postBlacklistChange } from 'api/blacklist';

const BlacklistReuploadModal = ({ modal, setModal }: { modal: any; setModal: any }) => {
	const { mutateAsync: blacklistChange } = useMutation(async (values: any) => {
		const formData = objectToFormData(values);

		const res = await postBlacklistChange(formData);
		return res.data;
	});

	const formik = useFormik({
		initialValues: {
			filePath: modal?.filePath,
			file: null,
		},
		validationSchema: yup.object({
			filePath: yup.string().required(),
			file: yup.mixed().required('File is required'),
		}),
		onSubmit: async (values) => {
			await blacklistChange(values, {
				onSuccess: () => setModal(null),
			});
		},
		enableReinitialize: true,
	});

	const { setFieldValue, errors } = formik;

	return (
		<Modal
			className="modal"
			aria-labelledby="blacklist-reupload-modal-label"
			show={Boolean(modal)}
			onEscapeKeyDown={() => setModal(null)}
			onBackdropClick={() => setModal(null)}
		>
			<FormikProvider value={formik}>
				<Form>
					<Modal.Header>
						<Modal.Title id="blacklist-reupload-modal-label">Reupload {modal?.name}</Modal.Title>
						<Button variant="close" type="button" aria-label="Close" onClick={() => setModal(null)}></Button>
					</Modal.Header>

					<Modal.Body className="modal-body">
						<FormControl
							// className="mt-3"
							name="file"
							type="file"
							accept=".csv"
							onChange={(e: any) => {
								const file = e.target.files[0];
								setFieldValue('file', file);
							}}
						/>

						{Object.keys(errors).length ? (
							<Alert variant="danger mt-3">{Object.values(errors).join(', ')}</Alert>
						) : null}
					</Modal.Body>

					<Modal.Footer className="modal-footer">
						<Button variant="secondary" type="button" onClick={() => setModal(null)}>
							Close
						</Button>
						<Button variant="primary" type="submit">
							Save Changes
						</Button>
					</Modal.Footer>
				</Form>
			</FormikProvider>
		</Modal>
	);
};

export default BlacklistReuploadModal;
