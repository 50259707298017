import React, { useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import BlacklistReuploadModal from './BlacklistReuploadModal';

const blacklists = [
	{
		name: 'Domain Blacklist',
		filePath: './uploads/blacklists/blacklist-domains.csv',
	},
	{
		name: 'Email Blacklist',
		filePath: './uploads/blacklists/blacklist-full-emails.csv',
	},
	{
		name: 'Company Ignore/Blacklist',
		filePath: './uploads/blacklists/blacklist-companies.csv',
	},
	{
		name: 'Domain TLD Blacklist (.gov, etc)',
		filePath: './uploads/blacklists/blacklist-email-endings.csv',
	},
	{
		name: 'Title Blacklist',
		filePath: './uploads/blacklists/blacklist-titles.csv',
	},
	{
		name: 'Prefix/Suffix Blacklist',
		filePath: './uploads/blacklists/blacklist-prefix-suffix.csv',
	},
];

const BlacklistPage = () => {
	const [modal, setModal] = useState<any | null>(null);

	return (
		<div>
			<Container>
				<h1 className="text-center my-5">Blacklists</h1>
				<h2 className="mt-3 mb-1">Current Blacklists</h2>

				<div className="mb-5">
					{blacklists.map((blacklist) => (
						<Card className="mt-3">
							<Card.Header>
								<b>{blacklist.name}</b>
							</Card.Header>
							<Card.Body>
								<Button variant="primary" as="a" href={blacklist.filePath}>
									Download
								</Button>
								<Button
									variant="success"
									className="mx-1"
									onClick={() => {
										setModal({
											name: blacklist.name,
											filePath: blacklist.filePath,
										});
									}}
								>
									Upload
								</Button>
							</Card.Body>
						</Card>
					))}
				</div>
			</Container>

			<BlacklistReuploadModal modal={modal} setModal={setModal} />
		</div>
	);
};

export default BlacklistPage;
