import React from 'react';
import { Alert, Button, Card, FormCheck, FormControl, Spinner } from 'react-bootstrap';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import { FormikProvider, Form } from 'formik';

const DashboardFilterForm = ({ formik, isLoading }: { formik: any; isLoading: boolean }) => {
	const { setFieldValue, values, errors } = formik;

	return (
		<FormikProvider value={formik}>
			<Form>
				<div className="my-4">
					<FormCheck className="mb-2">
						<FormCheckInput
							id="checkbox-validate"
							type="checkbox"
							name="validate"
							checked={values.validate}
							onInput={(e: any) => {
								setFieldValue('validate', e.target.value === 'on');
							}}
						/>
						<FormCheckLabel htmlFor="checkbox-validate">Use Internal Filter</FormCheckLabel>
					</FormCheck>

					<FormCheck className="mb-2">
						<FormCheckInput
							id="checkbox-validate-email"
							type="checkbox"
							name="validateEmail"
							checked={values.validateEmail}
							onInput={(e: any) => {
								setFieldValue('validateEmail', e.target.value === 'on');
							}}
						/>
						<FormCheckLabel htmlFor="checkbox-validate-email">Use API Email Filter</FormCheckLabel>
					</FormCheck>

					{!values.fileString && (
						<>
							<FormControl
								className="mt-3"
								name="file"
								type="file"
								accept=".csv"
								onChange={(e: any) => {
									const file = e.target.files[0];
									setFieldValue('file', file);
								}}
							/>
							<p className="text-muted mt-1">Max File Size: 246mb</p>
						</>
					)}

					{values.fileString && (
						<>
							<Card className="card mt-3">
								<Card.Header>Refilter Choosen File</Card.Header>
								<Card.Body>
									File: <a href={`${values.fileString}`}>{values.fileString}</a>
								</Card.Body>
								<Card.Footer>
									<Button
										variant="danger"
										onClick={() => {
											setFieldValue('fileString', null);
										}}
									>
										Cancel
									</Button>
								</Card.Footer>
							</Card>
						</>
					)}
				</div>

				{Object.keys(errors).length ? <Alert variant="danger">{Object.values(errors).join(', ')}</Alert> : null}
				<div className="mb-5 text-center">
					{isLoading ? (
						<Spinner variant="primary"></Spinner>
					) : (
						<Button variant="primary" size="lg" type="submit">
							Process List
						</Button>
					)}
				</div>
			</Form>
		</FormikProvider>
	);
};

export default DashboardFilterForm;
