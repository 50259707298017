import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardPage from './dashboard';
import BlacklistPage from './blacklist';

const Routes = () => {
	return (
		<>
			<Switch>
				<Route path="/" exact component={DashboardPage} />
				<Route path="/blacklists" component={BlacklistPage} />

				<Redirect to="/" />
			</Switch>
		</>
	);
};

export default Routes;
