import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import React, { useState } from 'react';
import 'bootstrap';
import { Alert, Button, Card, Container, ProgressBar } from 'react-bootstrap';
import { getDB } from 'api/db';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import DashboardFilterForm from './DashboardFilterForm';
import DashboardRenameModal from './DashboardRenameModal';
import { useFormik } from 'formik';
import { objectToFormData } from 'utils/formData';
import { postFilter } from 'api/filter';
import * as yup from 'yup';

const DashboardPage = () => {
	const queryClient = useQueryClient();

	const [modal, setModal] = useState<any | null>(null);

	const { data } = useQuery(
		['db'],
		async () => {
			const { data: res } = await getDB();

			return res.data;
		},
		{
			refetchInterval: 3000,
		}
	);

	// Filter Formik
	const { mutateAsync: filter, isLoading } = useMutation(async (values: any) => {
		const formData = objectToFormData(values);

		const res = await postFilter(formData);
		await queryClient.invalidateQueries(['db']);
		return res.data;
	});

	const filterFormik = useFormik({
		initialValues: {
			// Refilter
			fileString: '',

			// Filter
			file: '',
			validate: true,
			validateEmail: false,
		},
		onSubmit: async (values, { resetForm }) => {
			await filter(values, {
				onSuccess: () => {
					//@ts-expect-error
					document.querySelector("input[name='file']").value = '';
					resetForm();
				},
			});
		},
		validationSchema: yup.object({
			file: yup.mixed().test('file-size', 'File is too large', (file: any) => {
				if (typeof file === 'string') {
					return true;
				} else if (file && file.size <= 246 * 1024 * 1000) {
					return true;
				}
				return false;
			}),
			fileString: yup.string().test(function (value: any) {
				const { file } = this.parent;

				if (!file && !Boolean(value)) {
					return this.createError({
						message: 'File is required',
					});
				}
				return true;
			}),
		}),
		enableReinitialize: true,
	});

	const { setFieldValue } = filterFormik;

	return (
		<div>
			<Container>
				<h1 className="text-center my-5">Dashboard</h1>
				<h2 className="mt-3 mb-1">List Import</h2>

				<DashboardFilterForm formik={filterFormik} isLoading={isLoading} />

				<h2 className="mt-3 mb-2">Active Queues</h2>
				<div className="mb-5">
					{data?.queues.map((queue: any) => (
						<Card className="mt-3">
							<CardHeader>Queue #ID: {queue.id}</CardHeader>
							<Card.Body>
								<div>
									Number of records: <b>{queue.fileSize} Records</b>
								</div>
								<div className="mt-3 mb-2 text-center">Progress:</div>
								<div className="progress text-center">
									<ProgressBar aria-valuenow={queue.percentage} aria-valuemin={0} aria-valuemax={100}></ProgressBar>
								</div>
							</Card.Body>
							<Card.Footer className="text-muted">
								<p>Start Time: {dayjs(queue.startTime).format('MM/DD/YYYY HH:mm:ss')}</p>
							</Card.Footer>
						</Card>
					))}
				</div>

				<h2 className="mt-3 mb-2">Completed Lists</h2>
				<div className="mb-5">
					{data?.files.map((file: any) => (
						<Card className="mt-3">
							<CardHeader>File #ID: {file.id}</CardHeader>
							<Card.Body>
								<div>
									Initial Import: <a href={file.fromFile}>{file.fromFile}</a>
								</div>
								<div>
									Cleaned Export: <a href={file.toFile}>{file.toFile}</a>
								</div>
								<div>
									Initial Records: <b>{file.enterSize} Records</b>
								</div>
								<div>
									Cleaned Recrods: <b>{file.resultSize} Records</b>
								</div>

								<Button
									variant="primary"
									className="mt-3"
									onClick={() => {
										window.scrollTo(0, 0);

										setFieldValue('fileString', file.toFile);
									}}
								>
									Refilter
								</Button>
								<Button
									variant="success"
									className="mx-1 mt-3"
									onClick={() => {
										setModal({
											id: file.id,
											initial: file.fromFile,
											cleaned: file.toFile,
										});
									}}
								>
									Rename
								</Button>
							</Card.Body>
							<Card.Footer className="text-muted">
								Created At: {dayjs(file.createdAt).format('MM/DD/YYYY HH:mm:ss')}
							</Card.Footer>
						</Card>
					))}
				</div>

				<h2 className="mt-3 mb-2">Errors</h2>
				<div className="mb-5">
					{data?.errors.map((error: any) => (
						<Alert className="alert-danger mt-3">
							<p>Queue ID: {error.id}</p>
							<p className="mb-0">Message: {error.message}</p>
						</Alert>
					))}
				</div>
			</Container>

			<DashboardRenameModal modal={modal} setModal={setModal} />
		</div>
	);
};

export default DashboardPage;
