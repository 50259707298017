import request, { APICall } from 'api/request';

export const postBlacklistChange = (data: any): APICall<null> =>
	request({
		data,
		url: '/blacklist/change',
		method: 'POST',
		headers: {
			'Content-Type': 'multipart/form-data',
			Accept: 'application/json',
			type: 'formData',
		},
	});