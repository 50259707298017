import Layout from 'components/layout/Layout';
import ReactQueryProvider from 'components/providers/ReactQueryProvider';
import Routes from 'containers/Routes';
import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

function App() {
	return (
		<ReactQueryProvider>
			{/* 
                    // @ts-ignore */}
			<Router history={history}>
				<Layout>
					<Routes />
				</Layout>
			</Router>
		</ReactQueryProvider>
	);
}

export default App;
