import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { APICall } from './utils';

export const axiosInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

interface IRequest extends AxiosRequestConfig {}

const request = ({ ...options }: IRequest) => {
	// Authorization header with Bearer token is set up in PrivateRoute component
	const onSuccess = (response: AxiosResponse) => response;
	const onError = (error: AxiosError) => {
		/* tslint:disable:no-console */
		// DISPLAY API ERROR MESSAGES ON DEV ENVIRONMENT
		if (process.env.NODE_ENV === 'development') {
			if (error.response) {
				// Request was made but server responded with something
				// other than 2xx
				console.error('Status:', error.response.status);
				console.error('Data:', error.response.data);
				console.error('Headers:', error.response.headers);
			} else {
				// Something else happened while setting up the request
				// triggered the error
				console.error('Error Message:', error.message);
			}
		}
		/* tslint:enable:no-console */

		return Promise.reject(error.response || error.message);
	};

	return axiosInstance(options).then(onSuccess).catch(onError);
};

export default request;

export type { APICall };
