import React from 'react';
import { Alert, Button, FormControl, FormGroup, InputGroup, Modal } from 'react-bootstrap';
import { FormikProvider, Form, useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFilterRename } from 'api/filter';

const DashboardRenameModal = ({ modal, setModal }: { modal: any; setModal: any }) => {
	const queryClient = useQueryClient();

	const { mutateAsync: rename } = useMutation(async (values: any) => {
		const res = await postFilterRename({
			...values,
			fromInitialName: values.fromInitialName.replace('./uploads/files/', ''),
			fromResultName: values.fromResultName.replace('./uploads/files/', ''),
		});
		await queryClient.invalidateQueries(['db']);
		return res.data;
	});

	const formik = useFormik({
		initialValues: {
			fileId: modal?.id,
			fromInitialName: modal?.initial,
			initialName: '',
			fromResultName: modal?.cleaned,
			resultName: '',
		},
		validationSchema: yup.object({
			fromInitialName: yup.string().required(),
			initialName: yup.string(),
			fromResultName: yup.string().required(),
			resultName: yup.string(),
		}),
		onSubmit: async (values) => {
			await rename(values, {
				onSuccess: () => setModal(null),
			});
		},
		enableReinitialize: true,
	});

	const { values, setFieldValue, errors } = formik;

	return (
		<Modal
			className="modal"
			aria-labelledby="rename-modal-label"
			show={Boolean(modal)}
			onEscapeKeyDown={() => setModal(null)}
			onBackdropClick={() => setModal(null)}
		>
			<FormikProvider value={formik}>
				<Form>
					<Modal.Header>
						<Modal.Title id="rename-modal-label">Rename Files</Modal.Title>
						<Button variant="close" type="button" aria-label="Close" onClick={() => setModal(null)}></Button>
					</Modal.Header>

					<Modal.Body className="modal-body">
						<input name="fileId" type="hidden" />

						<FormGroup>
							<FormControl name="fromInitialName" hidden></FormControl>
							<label className="mb-1" htmlFor="initialName">
								Initial Import Name
							</label>
							<InputGroup>
								<InputGroup.Text>./uploads/files/</InputGroup.Text>
								<FormControl
									name="initialName"
									defaultValue={values.initialName}
									onChange={(e: any) => {
										setFieldValue('initialName', e.target.value);
									}}
								></FormControl>
							</InputGroup>
						</FormGroup>

						<FormGroup className="mt-2">
							<FormControl name="fromResultName" hidden></FormControl>
							<label className="mb-1" htmlFor="resultName">
								Cleaned Export Name
							</label>
							<InputGroup>
								<InputGroup.Text>./uploads/files/</InputGroup.Text>
								<FormControl
									name="resultName"
									defaultValue={values.resultName}
									onChange={(e: any) => {
										setFieldValue('resultName', e.target.value);
									}}
								></FormControl>
							</InputGroup>
						</FormGroup>
						{Object.keys(errors).length ? (
							<Alert variant="danger mt-3">{Object.values(errors).join(', ')}</Alert>
						) : null}
					</Modal.Body>

					<Modal.Footer className="modal-footer">
						<Button variant="secondary" type="button" onClick={() => setModal(null)}>
							Close
						</Button>
						<Button variant="primary" type="submit">
							Save Changes
						</Button>
					</Modal.Footer>
				</Form>
			</FormikProvider>
		</Modal>
	);
};

export default DashboardRenameModal;
