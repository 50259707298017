import React, { FC } from 'react';
import { WithChildren } from 'utils/types';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
	return (
		<div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style={{ width: '280px' }}>
			<a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
				{/* <svg className="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"></use></svg> */}
				<span className="fs-8">SafeGuard List Processing</span>
			</a>
			<hr />
			<Nav className=" nav-pills flex-column mb-auto">
				<Nav.Item>
					<NavLink to="/" exact className="nav-link text-white" activeClassName="active">
						Dashboard
					</NavLink>
				</Nav.Item>
				<Nav.Item>
					<NavLink to="/blacklists" exact className="nav-link text-white">
						Blacklists
					</NavLink>
				</Nav.Item>
			</Nav>
		</div>
	);
};

const Layout: FC<WithChildren<{}>> = ({ children }) => {
	return (
		<div className="d-flex" style={{ height: '100vh' }}>
			<Sidebar />

			<div className="w-100 px-2 overflow-scroll" style={{ height: '100%' }}>
				{children}
			</div>
		</div>
	);
};

export default Layout;
