import request, { APICall } from 'api/request';

export const postFilter = (data: any): APICall<null> =>
	request({
		data,
		url: '/filter',
		method: 'POST',
		headers: {
			'Content-Type': 'multipart/form-data',
			Accept: 'application/json',
			type: 'formData',
		},
	});

export const postFilterRename = (data: any): APICall<null> =>
	request({
		data,
		url: '/filter/rename',
		method: 'POST',
	});
